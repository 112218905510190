import React, { useEffect, useState } from 'react';
import { db } from '../../firebase/auth'; 
import { collection, getDocs, doc, getDoc, deleteDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { useAuth } from '../../contexts/authContext/index'; 
import Loading from './Loading';
import EditUnit from './EditUnit'; 
import { useNavigate } from 'react-router-dom';
// import GeneratePersonalizedRoutes from './GeneratePersonalizedRoutes';


const DisplayLearningUnits = ({showGenerateButton}) => { 
  const [learningUnits, setLearningUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null); // Estado para la unidad seleccionada
  const [loading, setLoading] = useState(true); // Estado para el estado de carga
  const { currentUser } = useAuth(); // Obtener el usuario actual
  const [editingUnitId, setEditingUnitId] = useState(null); // Estado para la unidad que se está editando
  const navigate = useNavigate();


  useEffect(() => {
    const fetchLearningUnits = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'groups'));
        let units = [];
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          if (data.teacherEmail === currentUser.email) { 
            if (Array.isArray(data.learningUnits)) { // Verificar que data.learningUnits sea un array
              for (const unitId of data.learningUnits) {
                const nombre = await fetchNombre(unitId);
                units.push({
                  id: doc.id,
                  groupName: data.groupName,
                  unitId,
                  nombre,
                  studentCount: data.students ? data.students.length : 0
                });
              }
            }
          }
        }
        units.sort((a, b) => {
          if (a.groupName === b.groupName) {
            return a.nombre.localeCompare(b.nombre);
          }
          return a.groupName.localeCompare(b.groupName);
        });
        setLearningUnits(units);
      } catch (error) {
        console.error('Error fetching learning units:', error);
      } finally {
        setLoading(false); // Finalizar el estado de carga
      }
    };

    const fetchNombre = async (unitId) => {
      try {
        const unitDoc = await getDoc(doc(db, 'learningUnits', unitId));
        return unitDoc.exists() && unitDoc.data().nombre ? unitDoc.data().nombre : 'N/A';
      } catch (error) {
        console.error('Error fetching nombre:', error);
        return 'N/A';
      }
    };

    if (currentUser) {
      fetchLearningUnits();
    }
  }, [currentUser]);

  const handleNavigateToLearningUnits = () => {
    navigate('/learning-units');
  };


  const handleEdit = (unitId) => {
    setEditingUnitId(unitId); // Establecer la unidad que se va a editar
  };

  const handleDelete = async (unitId) => {
    try {
      const unitDocRef = doc(db, 'learningUnits', unitId);
      const unitDocSnap = await getDoc(unitDocRef);
  
      if (unitDocSnap.exists()) {
        const unitData = unitDocSnap.data();
        const grupoId = unitData.grupo;
        await deleteDoc(unitDocRef);
        const grupoRef = doc(db, 'groups', grupoId);
        await updateDoc(grupoRef, {
          learningUnits: arrayRemove(unitId)
        });
        setLearningUnits(learningUnits.filter(unit => unit.unitId !== unitId));
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error deleting learning unit:', error);
    }
  };

  if (loading) {
    return <Loading />; // Mostrar el componente Loading mientras se cargan los datos
  }

  // Si se está editando una unidad, mostrar EditUnit
  if (editingUnitId) {
    return <EditUnit unitId={editingUnitId} />;
  }

  return (
    <div className="container md:w-1/2 mx-auto p-4 ">
      <h1 className="text-2xl font-bold mb-4 text-center">Mis Unidades de Aprendizaje</h1>
      {learningUnits.length === 0 ? (
        <div className="flex flex-col items-center bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-xl" role="alert">
        <p className="font-bold mb-2 text-center">¡Atención!</p>
        <p className="text-md text-center mb-4">Aún no ha creado ninguna unidad de aprendizaje</p>
        <div className="flex justify-center w-full">
          <button
            className="hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
            onClick={() => {
              // Redirigir a la página de creación de unidades
              handleNavigateToLearningUnits();
            }}
          >
            Crear Unidad
          </button>
        </div>
      </div>
      
      ) : (
        <div className="flex flex-col items-center overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200">☟</th>
                <th className="py-2 px-4 border-b border-gray-200">Grupo</th>
                <th className="py-2 px-4 border-b border-gray-200">Nombre</th>
                <th className="py-2 px-4 border-b border-gray-200">Número de estudiantes</th>
                <th className="py-2 px-4 border-b border-gray-200">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {learningUnits.map((unit) => (
                <tr key={unit.unitId}>
                  <td className="py-2 px-4 border border-gray-300">
                    <input
                      type="radio"
                      name="selectedUnit"
                      value={unit.unitId}
                      checked={selectedUnit === unit.unitId}
                      onChange={() => setSelectedUnit(unit.unitId)}
                      className="mr-2"
                    />
                  </td>
                  <td className="py-2 px-4 border border-gray-300">{unit.groupName || 'N/A'}</td>
                  <td className="py-2 px-4 border border-gray-300">{unit.nombre}</td>
                  <td className="py-2 px-4 border border-gray-300">{unit.studentCount}</td>
                  <td className="py-2 px-4 border border-gray-300">
                    {showGenerateButton ? selectedUnit === unit.unitId && (
                      <button
                        className="bg-amber-500 text-white px-2 py-1 rounded hover:bg-amber-700"
                        onClick={() => {
                          navigate('/ai-process/' + unit.unitId );
                            
                        }}
                      >
                        Generar Rutas 
                      </button>
                    ):( selectedUnit === unit.unitId && (
                      <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
                        <button 
                          className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                          onClick={() => handleEdit(unit.unitId)}
                        >
                          Editar
                        </button>
                        <button 
                          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
                          onClick={() => handleDelete(unit.unitId)}
                        >
                          Borrar
                        </button>
                      </div>
                    ))
                  }

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="mt-6 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
            onClick={() => {
              navigate('/home');
            }}
          >
            Regresar
          </button>
        </div>
      )}
    </div>
  );
};

export default DisplayLearningUnits;
