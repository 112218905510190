import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData, doSignOut } from '../../firebase/auth'; 
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Loading from './Loading';
import EmailVerification from './EmailVerification';
import WelcomeStudent from './WelcomeStudent';
import WelcomeProfesor from './WelcomeProfesor';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [error, setError] = useState('');
  const [teacherEmail, setTeacherEmail] = useState('');
  const [hasAnswers, setHasAnswers] = useState(false);

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();
  const db = getFirestore();

  // Fetch user data from Firebase and determine roles and status
  const fetchUserData = useCallback(async () => {
    try {
      const userData = await getUserData(currentUser.uid);
      setIsValidated(userData.isValidated);
      setIsTeacher(userData.isTeacher || false); // Default to false if undefined
      setTeacherEmail(userData.teacherEmail || '');

      // Check if the user has answers in Firestore
      const answersDoc = doc(db, 'users', currentUser.uid, 'answers', 'latest');
      const answersSnap = await getDoc(answersDoc);
      if (answersSnap.exists()) setHasAnswers(true);
      
    } catch (err) {
      console.error('Error fetching user data:', err);
      // setError('Error fetching user data');
    } finally {
      setLoading(false); // Loading finished
    }
  }, [currentUser, db]);

  // On component mount, fetch user data if a user is signed in
  useEffect(() => {
    if (!currentUser) {
      setLoading(false); // No user, stop loading
      return;
    }

    fetchUserData();
  }, [currentUser, fetchUserData]);

 

  const handleSignOut = async () => {
    try {
      await doSignOut();
      navigate('/login');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  // Handle loading state
  if (loading) return <Loading />;

  // Handle unvalidated users or missing teacher status
  // console.log('isValidated:', isValidated, 'isTeacher:', isTeacher);
  if (!isValidated) {
    return (
      <EmailVerification
        teacherEmail={teacherEmail}
        setTeacherEmail={setTeacherEmail}
        setIsValidated={setIsValidated}
        setError={setError}
        currentUser={currentUser}
        handleSignOut={handleSignOut}
        error={error}
      />
    );
  }
 // Navigate functions for different actions
  const handleNavigation = (path ) => navigate(path );

  // Render the appropriate welcome component based on role
  return isTeacher ? (
    <WelcomeProfesor
      currentUser={currentUser}
    />
  ) : (
    <WelcomeStudent
      currentUser={currentUser}
      handleNavigateToCHAEA={() => handleNavigation('/questioncomponent')}
      handleNavigateToScores={() => 
        handleNavigation('/scoring-answers', { currentUser: { uid: currentUser.uid, email: currentUser.email, displayName: currentUser.displayName, isValidated, isTeacher }})
      }
      handleSignOut={handleSignOut}
      hasAnswers={hasAnswers}
    />
  );
};

export default Home;
