import React, { useState, useEffect } from 'react';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/auth';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const CrearGrupo = () => {
  const initialFormData = {
    groupName: '',
    institution: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const teacherEmail = user.email;
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where("teacherEmail", "==", teacherEmail));
        const querySnapshot = await getDocs(q);
        const fetchedStudents = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setStudents(fetchedStudents);
      }
    };
    
    fetchStudents();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleStudentCheck = (e) => {
    const studentId = e.target.value;
    setSelectedStudents(prev => 
      e.target.checked
        ? [...prev, studentId]
        : prev.filter(id => id !== studentId)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const teacherEmail = user.email;

        await addDoc(collection(db, 'groups'), {
          ...formData,
          students: selectedStudents,
          teacherEmail: teacherEmail,
        });

        setFormSubmitted(true);
        setFormData(initialFormData);
      } else {
        console.error('No hay un usuario autenticado.');
      }
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  return (
    <div className="flex justify-center items-center mx-auto flex-col items-center w-3/4 max-w-2xl bg-white shadow-md rounded-2xl p-6 mt-10 py-10">
      <h2 className="text-2xl font-bold mb-4">Crear Grupo</h2>
      {!formSubmitted ? (
        <form onSubmit={handleSubmit}>
          <div className="mb-4 ">
            <div className="flex items-center">
              <span className="text-red-500">*</span>
              <label className="block text-gray-700 ml-1">Nombre del Grupo</label>
            </div>
            <input
              type="text"
              name="groupName"
              value={formData.groupName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg mt-2"
              required
            />
          </div>
          <div className="mb-4 relative">
            <div className="flex items-center">
              <span className="text-red-500">*</span>
              <label className="block text-gray-700 ml-1">Institución</label>
            </div>
            <input
              type="text"
              name="institution"
              value={formData.institution}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg mt-2"
              required
            />
          </div>
          <div className="mb-4 relative">
              <span className="text-red-500">*</span>
              <label className="block text-gray-700 ml-1 mb-4">Seleccionar estudiantes</label>
            <div className="max-h-100 overflow-y-auto border rounded-lg p-2">
            <div className="grid grid-cols-2 gap-4">
              {students.map(student => (
                <div key={student.id} className="flex items-center">
                  <input
                    type="checkbox"
                    id={student.id}
                    value={student.id}
                    checked={selectedStudents.includes(student.id)}
                    onChange={handleStudentCheck}
                    className="mr-2 flex-shrink-0"
                    /> 
                  <label className='whitespace-normal break-words overflow-wrap-normal hyphens-auto w-full' htmlFor={student.id}>{student.email}</label>
                </div>
              ))}
              </div>
            </div>
          </div>
          <button type="submit" className="w-full bg-custom-blue text-white py-2 px-4 rounded-lg hover:bg-blue-700">Enviar</button>
        </form>
      ) : (
        <div>
          <div className="mb-4 p-4 bg-green-100 text-green-700 border border-green-300 rounded-lg">
          Grupo creado exitosamente
          </div>
          <button onClick={ () => navigate('/welcome-profesor') } className="w-full bg-custom-blue text-white py-2 px-4 rounded-lg hover:bg-blue-700">
            Regresar
          </button>
        </div>
      )}
    </div>
  );
};

export default CrearGrupo;
