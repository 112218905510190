import React from 'react';

import CreateGroupButton from './CreateGroupButton';
import CreateLearningUnitButton from './CreateLearningUnitButton';
import ShowMyUnitsButton from './ShowMyUnitsButton';
import GeneratePersonalRoutesButton from './GeneratePersonalRoutesButton';
import SignOutButton from './SignOutButton';
import DisplayRoutesButton from './DisplayRoutesButton';

const WelcomeProfesor = ({ currentUser }) => {
  return (
    <div className='flex flex-col items-center w-full top-10 justify-center text-2xl font-bold pt-10 text-center'>
      <h1>Bienvenido(a) {currentUser.displayName ? currentUser.displayName : currentUser.email} a la experiencia PLUSabana.</h1>
      <h2 className='mt-4'>¿Qué deseas hacer hoy?</h2>

      <CreateGroupButton />

      <CreateLearningUnitButton />

      <ShowMyUnitsButton />

      <GeneratePersonalRoutesButton />

      <DisplayRoutesButton />


      <SignOutButton />

    </div>
  );

};

export default WelcomeProfesor;
