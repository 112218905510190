import React from 'react';
import { useNavigate } from 'react-router-dom';

const ShowMyUnitsButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/my-units');
    };

    return (
        <button
            onClick={handleClick}
            className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-700 mt-6"
        >
            Mis Unidades
        </button>
    );
};

export default ShowMyUnitsButton;