import React from 'react';
import Login from "./components/auth/login";
import Register from "./components/auth/register";

import Header from "./components/header";
import Home from "./components/home";
import PasswordReset from './components/auth/reset/PasswordReset'; 
import QuestionComponent from './components/home/question';
import ScoringAnswers from './components/home/scores';
import LearningUnitsForm from './components/home/LearningUnits';
import CrearGrupo from './components/home/CrearGrupo';
import MylearningUnits from './components/home/DisplayLearningUnits';
import GeneratePersonalizedRoutes from './components/home/GeneratePersonalizedRoutes';
import DisplayGeneratedRoutes from './components/home/DisplayGeneratedRoutes';


import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";

function App() {
  const routesArray = [
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/reset-password",
      element: <PasswordReset />,
    },
    {
      path: "/questioncomponent",
      element: <QuestionComponent />,
    },
    {
      path: "/scoring-answers",
      element: <ScoringAnswers />,
    },
    {
      path: "/learning-units",
      element: <LearningUnitsForm />,
    },
    {
      path: "/crear-grupo",
      element: <CrearGrupo />,
    },
    {
      path: "/my-units",
      element: <MylearningUnits />,
    },
    {
      path: "/generate-routes",
      element: <MylearningUnits showGenerateButton={true} />,
    },
    {
      path: "/ai-process/:unitId",
      element: <GeneratePersonalizedRoutes />,
    },
    {
      path: "/display-generated-routes",
      element: <DisplayGeneratedRoutes />,
    },
  ];
  let routesElement = useRoutes(routesArray);
  return (
    <AuthProvider>
      <Header />
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
