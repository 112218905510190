import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const InstructionsDisplay = ({ collection, document }) => {
  const [instructions, setInstructions] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInstructions = async () => {
      const db = getFirestore();
      try {
        const docRef = doc(db, collection, document );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            if (data && data.instructable) {
              setInstructions(data.instructable);
            } else {
              setError('No instructable field found in the document');
            }
          } else {
            setError('No instructions document found');
          }
        } catch (err) {
          console.error('Error fetching instructions:', err);
          setError('Error fetching instructions');
        }
      };

    fetchInstructions();
  }, [collection, document]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="mt-4">
      <h3 className="text-lg font-semibold mb-2">Documentación de apoyo:</h3>
      <div 
        className="p-4 bg-gray-100 rounded-lg"
        dangerouslySetInnerHTML={{ __html: instructions }}
      />
    </div>
  );
};

export default InstructionsDisplay;
