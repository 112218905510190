import React, { useState, useEffect } from 'react';
import { addDoc, collection, getDocs, query, where, updateDoc, doc, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/auth';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const LearningUnitsForm = () => {
  const initialFormData = {
    nombre: '',
    alcanceIncluido: '',
    alcanceExcluido: '',
    competenciasActitudinales: '',
    validacionActitudinal: '',
    competenciasCognitivas: '',
    validacionCognitiva: '',
    competenciasPerformativas: '',
    validacionPerformativa: '',
    horas: '',
    grupo: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [showInfo, setShowInfo] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [gruposOptions, setGruposOptions] = useState([]);
  const [infoMessages, setInfoMessages] = useState({});
  const [error, setError] = useState(null);
  const fibonacciSequence = [0, 1, 2, 3, 5, 8, 13];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGrupos = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const userEmail = user.email;
          const q = query(collection(db, 'groups'), where('teacherEmail', '==', userEmail));
          const querySnapshot = await getDocs(q);
          const gruposList = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().groupName }));
          setGruposOptions(gruposList);
        }
      } catch (error) {
        console.error('Error al obtener los grupos:', error);
      }
    };

    const fetchInfoMessages = async () => {
      try {
        const docRef = doc(db, 'content', 'LearningUnits.jsx');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setInfoMessages(docSnap.data().infoMessages || {});
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error('Error fetching info messages:', error);
      }
    };

    fetchGrupos();
    fetchInfoMessages();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Form data:', formData);
      const docRef = await addDoc(collection(db, 'learningUnits'), formData);
      const learningUnitId = docRef.id;

      const grupoRef = doc(db, 'groups', formData.grupo);
      await updateDoc(grupoRef, {
        learningUnits: arrayUnion(learningUnitId)
      });

      setFormSubmitted(true);
      //setFormData(initialFormData);
      //setFormData(initialFormData);
      //setTimeout(() => setFormSubmitted(false), 3000);
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      setError('Error al enviar el formulario. Por favor, intente de nuevo.');
    }
  };

  const handleNavigateHome = () => {
    navigate('/home');
  };

  const toggleInfo = (field) => setShowInfo({ ...showInfo, [field]: !showInfo[field] });

  const getInfoMessage = (field) => {
    return infoMessages[field] || 'Información no disponible.';
  };


  return (
    <div className="w-full max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6 mt-10 py-10">
      <h2 className="text-2xl font-bold mb-4">Formulario de Unidades de Aprendizaje</h2>
      <form onSubmit={handleSubmit}>
            {/* Mostrar mensaje de éxito después de enviar el formulario */}
      {formSubmitted ? (
        <div className="mb-4 p-4 bg-green-100 text-green-700 border border-green-300 rounded-lg">
          Formulario enviado exitosamente
          <button
            type="button"
            onClick={handleNavigateHome}
            className="ml-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
            >
            Regresar
          </button>
        </div>
        ) : (
            <>
              {/* Mostrar mensaje de error después de enviar el formulario */}
              {error && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 border border-red-300 rounded-lg">
                  {error}
                </div>
              )}
              <>
                <div>
                  <div className="mt-4">
                    <p className="text-gray-700 text-sm">Los campos marcados con <span className="text-red-500">*</span> son obligatorios.</p>
                  </div>
                  <div className="mt-2">
                    <p className="text-gray-700 text-sm pb-6">Las unidades de aprendizaje se asignan automáticamente al grupo seleccionado.</p>
                  </div>
                </div>
                {/* Grupo field */}
                <div className="mb-8">
                  <div className="flex items-center mb-2">
                    <label className="block text-gray-700 ml-1">Grupo</label>
                    <button
                      type="button"
                      onClick={() => toggleInfo('grupo')}
                      className="ml-2 text-blue-500 hover:bg-blue-400 hover:text-white"
                    >
                      <sup>&#9432; ?</sup>
                    </button>
                  </div>
                  <div className="relative">
                    {showInfo.grupo && (
                      <div className="absolute bg-gray-100 p-2 rounded-xl shadow-md mt-2">
                        <div dangerouslySetInnerHTML={{ __html: infoMessages.grupo || '' }}></div>
                      </div>
                    )}
                    <select
                      name="grupo"
                      value={formData.grupo}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg bg-white"
                      required
                    >
                      <option value="" disabled>Seleccione un grupo</option>
                      {gruposOptions.map((grupo) => (
                        <option key={grupo.id} value={grupo.id}>
                          {grupo.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Nombre field */}
                <div className="mb-8">
                  <div className="flex items-center mb-2">
                    <span className="text-red-500">*</span>
                    <label className="block text-gray-700 ml-1">Nombre</label>
                    <button
                      type="button"
                      onClick={() => toggleInfo('nombre')}
                      className="ml-2 text-blue-500 hover:bg-blue-400 hover:text-white"
                    >
                      <sup>&#9432; ?</sup>
                    </button>
                  </div>
                  <div className="relative">
                    {showInfo.nombre && (
                      <div className="absolute bg-gray-100 p-2 rounded-xl shadow-md mt-2">
                        <div dangerouslySetInnerHTML={{ __html: getInfoMessage('nombre') }}></div>
                      </div>
                    )}
                    <input
                      type="text"
                      name="nombre"
                      value={formData.nombre}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg bg-white"
                      required
                    />
                  </div>
                </div>
                {/* Alcance fields */}
                {['alcanceIncluido', 'alcanceExcluido'].map((field, index) => (
                  <div key={index} className="mb-4 relative">
                    <div className="flex items-center">
                      <span className="text-red-500">*</span>
                      <label className="block text-gray-700 ml-1">
                        {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                      </label>
                      <button
                        type="button"
                        onClick={() => toggleInfo(field)}
                        className="ml-2 text-blue-500 hover:bg-blue-400 hover:text-white"
                      >
                        <sup>&#9432; ?</sup>
                      </button>
                    </div>
                    {showInfo[field] && (
                      <div className="absolute bg-gray-100 p-2 rounded-xl shadow-md mt-2 z-50">
                        <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: getInfoMessage(field) }}></p>
                      </div>
                    )}
                    <input
                      type="text"
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-lg mt-2"
                      required
                    />
                  </div>
                ))}
                {/* Competencias fields */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  {['competenciasActitudinales', 'validacionActitudinal', 'competenciasCognitivas', 'validacionCognitiva', 'competenciasPerformativas', 'validacionPerformativa'].map((field, index) => (
                    <div key={index} className="relative">
                      <div className="flex items-center">
                        {field.includes('validacion') && <span className="text-red-500">*</span>}
                        <label className="block text-gray-700 ml-1">
                          {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim().replace('validacion', 'validación')}
                        </label>
                        <button type="button" onClick={() => toggleInfo(field)} className="ml-2 text-blue-500 hover:bg-blue-400 hover:text-white">
                          <sup>&#9432; ?</sup>
                        </button>
                      </div>
                      {showInfo[field] && (
                        <div className="absolute bg-gray-100 p-2 rounded-xl shadow-md mt-2z-50">
                          <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: getInfoMessage(field) }}></p>
                        </div>
                      )}
                      <input
                        type="text"
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-lg mt-2"
                        required={field.includes('validacion')}
                      />
                    </div>
                  ))}
                </div>
                {/* Dificultad field */}
                <div className="mb-8">
                  <label className="block text-gray-700">Dificultad en horas</label>
                  <div className="gap-4 mt-2">
                    <div>
                      <div className="flex items-center mb-2">
                        <label className="block text-gray-700 ml-1">Horas</label>
                        <button
                          type="button"
                          onClick={() => toggleInfo('dificultadHoras')}
                          className="ml-2 text-blue-500 hover:bg-blue-400 hover:text-white"
                        >
                          <sup>&#9432; ?</sup>
                        </button>
                      </div>
                      <div className="relative">
                        {showInfo.dificultadHoras && (
                          <div className="absolute bg-gray-100 p-2 rounded-xl shadow-md mt-2">
                            <div dangerouslySetInnerHTML={{ __html: getInfoMessage('dificultadHoras') }}></div>
                          </div>
                        )}
                        <select
                          name="horas"
                          value={formData.horas}
                          onChange={handleChange}
                          className="w-1/4 px-3 py-2 border rounded-lg bg-white"
                        >
                          {fibonacciSequence.map((value, index) => (
                            <option key={index} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            <button type="submit" className="w-full bg-custom-blue text-white py-2 px-4 rounded-lg hover:bg-blue-700">
              Enviar
            </button>
          </>
      )}

      </form>

      
    </div>
  );
};

export default LearningUnitsForm;