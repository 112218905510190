import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import BackHomeButton from './BackHomeButton';

const DisplayGeneratedRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoutes = async () => {
      if (!auth.currentUser) {
        setError('User not authenticated');
        setLoading(false);
        return;
      }

      const db = getFirestore();
      
      try {
        // Fetch the user document directly using the user's UID
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          setError('User document not found');
          setLoading(false);
          return;
        }

        const userData = userDocSnap.data();
        
        if (!userData.isTeacher) {
          setError('User is not a teacher');
          setLoading(false);
          return;
        }

        const aiPromptsRef = collection(db, 'users', auth.currentUser.uid, 'aiPrompts');
        const querySnapshot = await getDocs(aiPromptsRef);
        const routesData = [];
        for (const docSnap of querySnapshot.docs) {
            const data = docSnap.data();
            if (data.selectedStudents && Array.isArray(data.selectedStudents)) {
              for (const studentId of data.selectedStudents) {
                const studentDocRef = doc(db, 'users', studentId);
                const studentDocSnap = await getDoc(studentDocRef);
                if (studentDocSnap.exists()) {
                  const studentData = studentDocSnap.data();
                  routesData.push({
                    unitName: data.unitName,
                    student: studentData.email || studentId, // Use email if available, otherwise use ID
                    promptId: docSnap.id,
                    studentId: studentId // Keep the student ID for the handleViewRoute function
                  });
                }
              }
            }
          }

        setRoutes(routesData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching routes:', err);
        setError('Error fetching routes. Please try again.');
        setLoading(false);
      }
    };

    fetchRoutes();
  }, [auth.currentUser]);

  const handleViewRoute = (promptId, student) => {
    navigate('/show-route', { state: { promptId, student } });
  };

  if (loading) return (<Loading />);
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Generaciones de Rutas Personalizadas</h2>
      {routes.length === 0 ? (
        <p>No routes found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Nombre</th>
                <th className="py-3 px-6 text-left">Estudiante</th>
                <th className="py-3 px-6 text-center">Ruta</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {routes.map((route, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    {route.unitName}
                  </td>
                  <td className="py-3 px-6 text-left">
                    {route.student}
                  </td>
                  <td className="py-3 px-6 text-center">
                    <button
                      onClick={() => handleViewRoute(route.promptId, route.studentId)}
                      className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Ver ruta
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <BackHomeButton />
          
          
        </div>
      )}
    </div>
  );
};

export default DisplayGeneratedRoutes;

