import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackHomeButton = () => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-center mt-4">
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => navigate('/home')}
            >
                Regresar
            </button>
        </div>
    );
};

export default BackHomeButton;
