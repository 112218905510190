import React, { useState, useEffect } from 'react';
import { getFirestore, doc, setDoc, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

const AiPersonalRoutes = ({ selectedStudents, aiPrompt, unitName }) => {
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();

  const navigate = useNavigate();
  useEffect(() => {
    const storeAiPrompts = async () => {
      const db = getFirestore();
      if (!aiPrompt || typeof aiPrompt !== 'string' || aiPrompt.trim() === '') {
        setStatus('Prompt de IA no válido. Por favor, proporcione un prompt válido.');
        console.error('Invalid AI . Please provide a valid prompt.');
        return;
      }
            
      setStatus('Almacenando prompts de IA...');
      setIsLoading(true);

      try {
        const teacherId = auth.currentUser.uid;
        const teacherDocRef = doc(db, 'users', teacherId);
        const promptDocRef = doc(teacherDocRef, 'aiPrompts', unitName);

        await setDoc(promptDocRef, {
          prompt: aiPrompt,
          createdAt: new Date(),
          unitName: unitName,
          selectedStudents: arrayUnion(...selectedStudents)
        }, { merge: true });

        setStatus('Prompts de IA almacenados exitosamente!');
        console.log('AI prompts stored successfully!');
      } catch (error) {
        console.error('Error storing AI prompts:', error);
        setStatus('Error al almacenar prompts de IA.');
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedStudents.length > 0 && aiPrompt && unitName && auth.currentUser) {
      storeAiPrompts();
    }
  }, [selectedStudents, aiPrompt, unitName, auth.currentUser]);

  return (
      <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Estado de almacenamiento de prompts de IA:</h3>
          <div className="flex items-center">
              {isLoading ? (
                  <>
                      <Loading />
                      <p className="ml-2">{status}</p>
                  </>
              ) : (
                  <div>
                      <p>{status}</p>
                      <button
                          onClick={() =>
                              navigate('/welcome-profesor')
                          }
                          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
                          Regresar
                      </button>
                  </div>
              )}
          </div>
      </div>
  );
};

export default AiPersonalRoutes;
