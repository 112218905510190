import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import Loading from './Loading';
import InstructionsDisplay from './InstructionsDisplay';  // Adjust the import path as needed
import AiPersonalRoutes from './AiPersonalRoutes';

const GeneratePersonalizedRoutes = () => {
    const { unitId } = useParams();
    const [unitName, setUnitName] = useState('');
    const [groups, setGroups] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [aiPrompt, setAiPrompt] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            try {
                // Fetch the learning unit
                const unitDoc = await getDoc(doc(db, 'learningUnits', unitId));
                if (unitDoc.exists()) {
                    setUnitName(unitDoc.data().nombre);
                } else {
                    throw new Error('Learning unit not found');
                }

                // Fetch groups containing this learning unit
                const groupsQuery = query(
                    collection(db, 'groups'),
                    where('learningUnits', 'array-contains', unitId)
                );
                const groupsSnapshot = await getDocs(groupsQuery);

                // Collect all students
                let allStudents = new Set();
                const groupsData = [];
                groupsSnapshot.forEach((groupDoc) => {
                    const groupData = groupDoc.data();
                    groupsData.push({
                        id: groupDoc.id,
                        name: groupData.groupName,
                        students: groupDoc.data().students || []
                    });
                    groupData.students.forEach(student => allStudents.add(student));
                });
                setGroups(groupsData);
                // Fetch email and questionnaire status for each student
                const studentPromises = Array.from(allStudents).map(async (studentId) => {
                    const studentDoc = await getDoc(doc(db, 'users', studentId));
                    if (studentDoc.exists()) {
                        const answersQuery = await getDocs(collection(db, 'users', studentId, 'answers'));
                        return {
                            id: studentId,
                            email: studentDoc.data().email,
                            hasAnswers: !answersQuery.empty
                        };
                    }
                    return null;
                });

                const studentData = await Promise.all(studentPromises);
                setStudents(studentData.filter(student => student !== null));
            } catch (err) {
                console.error('Error fetching data:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [unitId]);

    const handleStudentSelection = (studentId) => {
        setSelectedStudents(prev =>
            prev.includes(studentId)
                ? prev.filter(id => id !== studentId)
                : [...prev, studentId]
        );
    };

    const handlePromptChange = (event) => {
        setAiPrompt(event.target.value);
    };
    const handleGenerateRoutes = () => {
        if (selectedStudents.length > 0 && aiPrompt) {
            setIsGenerating(true);
          } else {
            alert('Por favor, seleccione estudiantes y proporcione un prompt de IA antes de generar rutas.');
            console.log('Please select students and provide an AI prompt before generating routes.');
          }
        // console.log('Generating routes for students:', selectedStudents);
        // console.log('AI Prompt:', aiPrompt);
    };
    
    if (loading) return <Loading />;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">Generación de Rutas Personalizadas usando IA</h1>
            <h2 className="text-xl font-semibold mb-1 bg-green-100 p-4 rounded-xl shadow-md text-center">Unidad de Aprendizaje: <strong>{unitName}</strong></h2>

            {groups.map(group => (
                <div key={group.id} className="mb-6">
                    <h3 className="text-xl font-semibold mb-4 bg-green-100 p-4 rounded-xl shadow-md text-center mb-6">Grupo: {group.name}</h3>
                    <p className="text-xl font-semibold mb-2">Seleccione los estudiantes a quienes se va a generar la ruta:</p>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {students
                            .filter(student => group.students.includes(student.id))
                            .map(student => (
                                <div key={student.id} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        id={student.id}
                                        checked={selectedStudents.includes(student.id)}
                                        onChange={() => handleStudentSelection(student.id)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <label
                                        htmlFor={student.id}
                                        className={`${student.hasAnswers ? 'text-green-500' : 'text-red-500'}`}
                                        title={student.hasAnswers ? '' : 'Advertencia: Este estudiante no ha completado el cuestionario!'}
                                    >
                                        {student.email}
                                    </label>
                                </div>
                            ))}
                    </div>
                </div>
            ))}
            <div className="mt-8 flex flex-col md:flex-row">
                <div className="md:w-[70%] pr-4">
                    <label htmlFor="ai-prompt" className="block text-xl font-semibold mb-2">
                        Instrucciones (Prompt) para el sistema de IA:
                    </label>
                    <textarea
                        id="ai-prompt"
                        value={aiPrompt}
                        onChange={handlePromptChange}
                        placeholder="Cree aquí su prompt para el sistema de IA, siguiendo las instrucciones que se presentan más abajo.
                        Es posible copiar y pegar, si resulta más fácil.
                        Esta ventana de texto puede estirarse hacia abajo para ver los textos completos.
                        Para ello, deslícela usando el ángulo inferior derecho de la ventana."
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        rows="4"
                    ></textarea>
                </div>
                <div className="md:w-[30%] mt-4 md:mt-0 container mx-auto p-4">
                  <div className=" mt-4 md:mt-0 items-end">
                    <div className="w-full bg-blue-100 p-3 rounded-3xl shadow">
                        <h3 className="text-xl font-semibold mb-2">Recuerde:</h3>
                        <ol className="list-decimal list-inside mb-4">
                            <li>El prompt debe ser estructurado en español.</li>
                            <li>El prompt debe ser lo más específico posible.</li>
                            <li>El prompt debe ser lo más corto posible.</li>
                            <li>Solamente debe presionar el botón dorado de aquí abajo cuando el prompt de instrucciones esté del todo listo y completo☟☟☟.</li>
                        </ol>
                    </div>
                    <button
                    onClick={handleGenerateRoutes}
                        className="w-full bg-amber-500 hover:bg-amber-600 text-white font-bold text-xl py-2 px-4 rounded-full transition duration-300 ease-in-out"
                    >
                        Genere la ruta de aprendizaje para los estudiantes seleccionados
                    </button>
                        {isGenerating && (
                            <AiPersonalRoutes
                                selectedStudents={selectedStudents}
                                aiPrompt={aiPrompt}
                                unitName={unitName}
                            />
                        )}
                </div>  
                </div>
                
            </div>
            <div className="mt-8">
                <h3 className="text-xl font-semibold mb-2">Estudiantes seleccionados:</h3>
                <ul>
                    {selectedStudents.map(studentId => (
                        <li key={studentId}>
                            {students.find(student => student.id === studentId)?.email || studentId}
                        </li>
                    ))}
                </ul>
            </div>
            
            <InstructionsDisplay collection ="content" document="promptingAI"/>


        </div>
    );
};

export default GeneratePersonalizedRoutes;
