import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const SignOutButton = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const handleClick = async () => {
        try {
            await signOut(auth);
            localStorage.clear();
            document.cookie.split(';').forEach(function(c) {
                document.cookie = c.replace(/=(.*)/, '=; expires=Thu, 01 Jan 1970 00:00:00 GMT');
              });
            // console.log('Signed out successfully!');
            navigate('/');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <button
            onClick={handleClick}
            className="px-4 py-2 bg-red-500 text-white text-lg font-bold rounded hover:bg-red-700 mt-12"
        >
            Cerrar Sesión
        </button>
    );
};

export default SignOutButton;
