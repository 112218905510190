import React  from 'react';
import { useNavigate } from 'react-router-dom';

const GeneratePersonalRoutesButton = () => {
    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate('/generate-routes');
    };

    return (
        <div>
            <button
                onClick={handleClick}
                className="px-4 py-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-700 mt-6"
            >
                Generar Rutas Personales
            </button>
        </div>
    );
};

export default GeneratePersonalRoutesButton;




