import React from 'react';
import { useNavigate } from 'react-router-dom';

const DisplayRoutesButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/display-generated-routes');
    };

    return (
        <button
            onClick={handleClick}
            className="px-4 py-2 bg-amber-400 text-white rounded-full hover:bg-amber-700 mt-6"
        >
            Mis Rutas Personalizadas
        </button>
    );
};

export default DisplayRoutesButton;