import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateGroupButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className="bg-orange-500 blue text-white py-2 px-4 rounded-full hover:bg-orange-700 mt-6 mb-1"
      onClick={() => navigate('/crear-grupo')}
      
    >
      Crear Grupo
    </button>
  );
};

export default CreateGroupButton;